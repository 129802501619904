import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useSpellCheck from './useSpellCheck';

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

const Toggle = ({ isOn, onToggle }) => {
  return (
    <div 
      onClick={onToggle} 
      className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${
        isOn ? 'bg-green-400' : 'bg-gray-300'
      }`}
    >
      <div 
        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
          isOn ? 'translate-x-6' : ''
        }`}
      ></div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">How it works</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const App = () => {
  const { uuid } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [charCounts, setCharCounts] = useState({
    paragraph: 120,
    featureOne: 53,
    featureTwo: 53,
    featureThree: 53
  });
  const [paragraphText, setParagraphText] = useState('');
  const [featureTexts, setFeatureTexts] = useState({
    featureOne: '',
    featureTwo: '',
    featureThree: ''
  });
  const [paragraphErrors, setParagraphErrors] = useState([]);
  const [featureErrors, setFeatureErrors] = useState({
    featureOne: [],
    featureTwo: [],
    featureThree: []
  });
  const [driverStatus, setDriverStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const spellCheck = useSpellCheck();

  const tooltips = {
    paragraph: "Include information about what your team can offer, such as benefits, team culture, or unique opportunities.",
    feature: "Highlight specific benefits like pay rates, sign-on bonuses, or anything that might attract a candidate to work for you.",
    driverToggle: "Toggling on may reduce the number of candidates that you receive from Growth Alliance."
  };

  useEffect(() => {
    setParagraphErrors(spellCheck(paragraphText));
  }, [paragraphText, spellCheck]);

  useEffect(() => {
    setFeatureErrors({
      featureOne: spellCheck(featureTexts.featureOne),
      featureTwo: spellCheck(featureTexts.featureTwo),
      featureThree: spellCheck(featureTexts.featureThree)
    });
  }, [featureTexts, spellCheck]);

  const fetchData = useCallback(async () => {
    console.log("Fetching data for UUID:", uuid);
    setLoading(true);
    try {
      const response = await fetch('https://hook.eu2.make.com/r5qrny65o6awjtdtxp9i2lmntu1s58ve', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        body: JSON.stringify({ uuid }),
      });
      const result = await response.json();
      console.log("Received data:", result);
      
      setData(result);
      setParagraphText(result.paragraph || '');
      setFeatureTexts({
        featureOne: result.featureOne || '',
        featureTwo: result.featureTwo || '',
        featureThree: result.featureThree || ''
      });
      setCharCounts({
        paragraph: 120 - (result.paragraph?.length || 0),
        featureOne: 53 - (result.featureOne?.length || 0),
        featureTwo: 53 - (result.featureTwo?.length || 0),
        featureThree: 53 - (result.featureThree?.length || 0)
      });
      setDriverStatus(result.driverStatus || false);
      
      console.log("Updated state:", {
        paragraphText: result.paragraph || '',
        featureTexts: {
          featureOne: result.featureOne || '',
          featureTwo: result.featureTwo || '',
          featureThree: result.featureThree || ''
        },
        driverStatus: result.driverStatus || false
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      setData(null);
      setParagraphText('');
      setFeatureTexts({featureOne: '', featureTwo: '', featureThree: ''});
      setDriverStatus(false);
      
      fetchData();
    }
  }, [uuid, fetchData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await fetch('https://hook.eu2.make.com/t5hjoekq693o403xth9ugxg41y8okts7', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid,
          officeName: data.officeName,
          paragraph: paragraphText,
          ...featureTexts,
          driverStatus: driverStatus
        }),
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleGoBack = () => {
    setSubmitted(false);
    fetchData();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const maxLength = name === 'paragraph' ? 120 : 53;
    if (value.length <= maxLength) {
      setCharCounts(prev => ({
        ...prev,
        [name]: maxLength - value.length
      }));
      if (name === 'paragraph') {
        setParagraphText(value);
      } else {
        setFeatureTexts(prev => ({
          ...prev,
          [name]: value
        }));
      }
    }
  };

  const TooltipContent = ({ content }) => (
    <div className="p-2 max-w-xs">
      <p className="text-sm">{content}</p>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <div className="h-8 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="h-4 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="h-12 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="h-12 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="h-12 bg-gray-200 rounded mb-4 animate-pulse"></div>
          <div className="h-32 bg-gray-200 rounded mb-4 animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <div className="text-center mb-8">
            <p className="text-2xl font-bold text-green-600 mb-4">Thank you, everything's been updated.</p>
            <button onClick={handleGoBack} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              Go Back
            </button>
          </div>
          <div className="mt-8 p-6 bg-yellow-50 rounded-lg border-2 border-yellow-200">
            <h3 className="text-xl font-semibold text-yellow-800 mb-3">Tips and Suggestions</h3>
            <p className="text-yellow-700 leading-relaxed">
              It's a good idea to keep your features and benefits up to date. You can return to this link at any time to refresh them. Maintaining current information will help attract the right Care Professionals to your roles.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">{data?.officeName || 'Office'}</h2>
        <p className="mb-2 text-gray-600">Add or update information about the Care Professional job role. Saving will take immediate effect.</p>
        <p className="mb-6 text-blue-600 cursor-pointer hover:underline" onClick={() => setIsModalOpen(true)}>
          How it works
        </p>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <p className="mb-4">
            Easily update the features, benefits, and requirements for candidates in your territory. 
            These details will be highlighted during the application process.
          </p>
          <p className="mb-4 font-semibold">
            Tip: Keep features concise and focus on what benefits the candidate.
          </p>
          <img 
            src="https://i.ibb.co/fdSmNSM/asdxcdws.jpg" 
            alt="How it works" 
            className="w-full rounded-lg"
          />
        </Modal>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="paragraph" className="block mb-2 text-sm font-medium text-gray-700">
              Paragraph 
              <span className="text-gray-500 ml-2">
                Up to 120 characters ({charCounts.paragraph} remaining)
              </span>
              <Tippy content={<TooltipContent content={tooltips.paragraph} />}>
                <span className="inline-block ml-1 cursor-help">
                  <InfoIcon />
                </span>
              </Tippy>
            </label>
            <textarea
              id="paragraph"
              name="paragraph"
              value={paragraphText}
              onChange={handleInputChange}
              maxLength={120}
              className={`w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 ${paragraphErrors.length > 0 ? 'border-red-500' : ''}`}
              rows="4"
            />
            {paragraphErrors.length > 0 && (
              <p className="text-red-500 text-sm mt-1">Possible spelling errors: {paragraphErrors.join(', ')}</p>
            )}
          </div>
          {['One', 'Two', 'Three'].map((num) => (
            <div key={num} className="mb-4">
              <label htmlFor={`feature${num}`} className="block mb-2 text-sm font-medium text-gray-700">
                Feature {num} 
                <span className="text-gray-500 ml-2">
                  Up to 53 characters ({charCounts[`feature${num}`]} remaining)
                </span>
                <Tippy content={<TooltipContent content={tooltips.feature} />}>
                  <span className="inline-block ml-1 cursor-help">
                    <InfoIcon />
                  </span>
                </Tippy>
              </label>
              <input
                type="text"
                id={`feature${num}`}
                name={`feature${num}`}
                value={featureTexts[`feature${num}`]}
                onChange={handleInputChange}
                maxLength={53}
                className={`w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 ${featureErrors[`feature${num}`].length > 0 ? 'border-red-500' : ''}`}
              />
              {featureErrors[`feature${num}`].length > 0 && (
                <p className="text-red-500 text-sm mt-1">Possible spelling errors: {featureErrors[`feature${num}`].join(', ')}</p>
              )}
            </div>
          ))}
          <div className="mb-6">
            <div className="flex items-center justify-between">
              <label htmlFor="driverToggle" className="text-sm font-medium text-gray-700">
                Request Car Drivers
                <Tippy content={<TooltipContent content={tooltips.driverToggle} />}>
                  <span className="inline-block ml-1 cursor-help">
                    <InfoIcon />
                  </span>
                </Tippy>
              </label>
              <Toggle isOn={driverStatus} onToggle={() => setDriverStatus(!driverStatus)} />
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Toggle on to request applicants have their own car and can drive.
            </p>
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Save and Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default App;
