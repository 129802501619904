import { useCallback } from 'react';

// This is a very basic list of UK spellings. You should expand this list.
const ukSpellings = [
  'colour', 'flavour', 'behaviour', 'labour', 'neighbour', 'honour', 'rumour', 'favour', 'odour', 'vigour',
  'centre', 'fibre', 'litre', 'metre', 'theatre', 'spectre', 'kilometre', 'centimetre', 'millimetre', 'sceptre',
  'defence', 'licence', 'offence', 'pretence', 'practice', 'parlour', 'armour', 'clamour', 'glamour', 'harbour',
  'analyse', 'catalyse', 'paralyse', 'breathalyse', 'dialyse', 'electrolyse', 'hydrolyse', 'polymerise', 'minimise', 'maximise',
  'organisation', 'realise', 'recognise', 'materialise', 'apologise', 'authorise', 'customise', 'mobilise', 'legalise', 'specialise',
  'programme', 'enrolment', 'judgement', 'inflexion', 'smoulder', 'travelling', 'grey', 'mould', 'plough', 'kerb',
  'sulphur', 'aeroplane', 'aluminium', 'cheque', 'tyre', 'kerosene', 'doughnut', 'pyjamas', 'storey', 'cosy',
  'caregiver', 'carer', 'personalise', 'supervise', 'specialised', 'valued', 'recognised', 'professionalise', 'compassionate', 'organised',
  'licence', 'licenced', 'programme', 'endeavour', 'liaise', 'teamwork', 'flexibility', 'supportive', 'empathise', 'responsibility',
  'counsellor', 'support worker', 'client-centred', 'commitment', 'safeguarding', 'recognition', 'continuity', 'care management', 'person-centred', 'reliable',
  'communication', 'dedication', 'supervision', 'optimise', 'regularise', 'stabilise', 'fulfilment', 'customisation', 'prioritise', 'organiser'
];

const useSpellCheck = () => {
  const checkSpelling = useCallback((text) => {
    const words = text.split(/\s+/);
    const misspelledWords = words.filter(word => {
      // Check if the word is in our UK spellings list
      if (ukSpellings.includes(word.toLowerCase())) {
        return false;
      }
      // Use the browser's spell checker
      const textarea = document.createElement('textarea');
      textarea.innerHTML = word;
      return textarea.misspelledWords && textarea.misspelledWords.length > 0;
    });
    return misspelledWords;
  }, []);

  return checkSpelling;
};

export default useSpellCheck;
